/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import "colors";
@import "icons";
@import "fonts";
@import "default";
@import "nav";
@import "buttons";
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #444;
}
.wrap {
  padding: 30px 0;
}
// .container {
//   max-width: 1350px;
// }
.container-fluid {
  &.but-fixed {
    // max-width: 1230px;
    max-width: 1350px;
    // &.wider {
    //   max-width: 1350px;
    // }
  }
}
.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  background-color: $theme-primary;
  color: #ffffff;
  // border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  font-weight: 300;
  a,
  h3,
  h4 {
    color: #ffffff;
  }
  .social a {
    border: 0;
  }
}

/* --------- asc styles --------- */
#header-wrap {
  background-color: $theme-primary;
}
#header {
  // background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 100px;
    img {
      display: inline-block;
      // margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 100%;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: #780019;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\ea77";
        color: inherit;
        font-family: "Material Icons Round";
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten(#780019, 7.5%);
      }
    }
    a.cart {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: #780019;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\e8cc";
        color: inherit;
        font-family: "Material Icons Outlined";
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten(#780019, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\e830";
        color: inherit;
        font-family: "icons";
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
    #language {
      margin-top: 20px;
      float: right;
      clear: right;
      width: 170px;
    }
  }
  .main-phone {
    .icon-phone:before {
      margin-right: 4px;
      font-size: 1.3em;
      transform: rotate(-100deg);
    }
  }
}
/* ----------------------------------------------------------------------------
    Blogs styles
----------------------------------------------------------------------------- */
.post {
  padding: 20px 0;
  border-bottom: 1px solid #dee2e6;
  overflow: hidden;
  p:last-child {
    margin-bottom: 0;
  }
  .post-img {
    max-width: 200px;
  }
  .post-details {
    overflow: hidden;
  }
  .published-date {
    opacity: 0.7;
    font-size: 0.9rem;
  }
  .more-link {
    display: none;
    &:hover {
      margin-bottom: -1px;
    }
  }
}
.screen-reader-text {
  display: none;
}
figure {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    padding: 0.25rem;
    border-radius: 0.25rem;
    border: 1px solid #dee2e6;
  }
  figcaption {
    margin-top: 1rem;
  }
  &.alignright {
    float: right;
    max-width: 40%;
    margin-left: 1.5rem;
  }
  &.alignleft {
    float: left;
    max-width: 40%;
    margin-right: 1.5rem;
  }
}

/* ----------------------------------------------------------------------------
    global styles
----------------------------------------------------------------------------- */
.faded-blue-bg {
  position: relative;
  &:before {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0.7;
    background-color: #1d3c45;
  }
}
#support-wrap {
  border-top: 1px solid #fff;
  background-color: $theme-primary;
  a {
    padding: 3px 0 3px 0;
    color: #fff;
    background-color: #780019;
    border: 0;
    &:first-child {
      border-right: 1px solid #fff;
    }
    &:hover {
      background-color: lighten(#780019, 5%);
    }
    &.login:before,
    &.cart:before {
      display: inline-block;
      margin-right: 5px;
      color: inherit;
      content: "\ea77";
      font-family: "Material Icons Round";
      font-weight: normal;
      font-size: 105%;
      vertical-align: top;
    }
    &.cart:before {
      content: "\e8cc";
      font-family: "Material Icons Outlined";
    }
  }
}
#banner-wrap {
  background: transparent url(/images/banner-bg-sm.jpg) no-repeat 50% 50% / cover;
  h1,
  h2,
  h3 {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  &.lp {
    background: linear-gradient(to right, $theme-primary 0%, #2c5560 50%, $theme-primary 100%);
  }
  &.faded-bg {
    h1,
    h2,
    h3 {
      text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.5);
    }
    position: relative;
    &:before {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0.7;
      background-color: #fff;
    }
  }
  &.about-us,
  &.contact-us,
  &.work-with-us,
  &.spanish,
  &.ethical {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      background: #fff;
      width: 100%;
      height: 50px;
      clip-path: polygon(50% 100%, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
  &.spanish {
    background: transparent url(/images/spanish-bg-sm.jpg) no-repeat 50% 20% / cover;
  }
  &.ethical {
    background: transparent url(/images/ethical-contractor-bg-sm.jpg) no-repeat 50% 20% / cover;
  }
}

#phone-wrap {
  background-color: #1fbf66;
  color: #00532b;
  .open-phone {
    background-color: #015f32;
    color: #e3ffba;
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border: 22px solid transparent;
      border-left: 25px solid #1fbf66;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &::after {
      left: auto;
      right: 0;
      border: 22px solid transparent;
      border-right: 25px solid #1fbf66;
    }
  }
}
#nascla-wrap {
  background-image: linear-gradient(13deg, #9e4913 55%, #c05c1e 55%);
}
#testimonial-wrap {
  background: transparent url(/images/testimonial-bg-sm.jpg) no-repeat 50% 50% / cover;
}

.bg-light-blue {
  background-color: #e5eef3;
}
.card {
  &.border-3 {
    border-width: 3px;
  }
  &.border-theme-primary {
    border-color: $theme-primary;
  }
  &.review {
    padding-top: 20px;
    border-color: $theme-secondary;
    position: relative;
    &:before {
      margin: 0 auto;
      display: block;
      width: 60px;
      height: 60px;
      content: "\201D";
      font-family: Georgia, "Times New Roman", Times, serif;
      font-size: 60px;
      line-height: 1.4;
      color: $theme-primary;
      background-color: #fff;
      font-weight: 700;
      text-align: center;
      border: 3px solid $theme-secondary;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
    }
  }
  &.about-us {
    border: 0;
    border-top: 5px solid $theme-secondary;
    background-color: $theme-primary;
    .img-fluid {
      max-width: 220px;
    }
  }
  &.contact-us {
    border-color: $theme-secondary;
    // border: 0;
    // background-color: lighten($theme-primary, 65%);
    .img-fluid {
      max-width: 220px;
    }
    a.phone-link {
      border-top: 3px solid $theme-primary;
      color: $theme-primary;
    }
  }
  &.course-type {
    border-color: $theme-primary;
    margin-top: 50px;
    .icon {
      margin-top: -43px;
      margin-left: 1.25rem;
      padding: 15px;
      width: 86px;
      height: 86px;
      border: 3px solid $theme-primary;
      color: #444;
      background-color: #fff;
      border-radius: 0.3rem;
      text-align: center;
    }
  }
  &.course-box {
    z-index: 0;
    .course-img {
      opacity: 0.75;
      transition: opacity 0.15s ease-in-out;
    }
    .ribbon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      overflow: hidden;
      position: absolute;
      top: -3px;
      right: -3px;
      &:before,
      &:after {
        position: absolute;
        z-index: -1;
        content: "";
        display: block;
        height: 3px;
        width: 3px;
        background-color: darken($theme-primary, 100%);
      }
      &:before {
        top: 0;
        left: 0;
        border-top-left-radius: 2px;
      }
      &:after {
        bottom: 0;
        right: 0;
        border-bottom-right-radius: 2px;
      }
      span {
        position: absolute;
        display: block;
        width: 120px;
        padding: 2px 0;
        background-color: $theme-primary;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        text-align: center;
        top: 16px;
        left: -17px;
        transform: rotate(45deg);
      }
    }
    &.border-3 {
      .ribbon {
        top: -6px;
        right: -6px;
      }
    }
    &.vertical {
      .course-img {
        padding: 0.25rem;
        background-color: #fff !important;
        border: 1px solid #dee2e6;
        .card-img-top {
          border-radius: 0;
        }
      }
    }
    &:hover {
      .course-img {
        opacity: 1;
      }
    }
  }
}
.staff {
  padding: 0 0.75rem;
  .img-fluid {
    max-width: 150px;
  }
}
.line-seperator {
  position: relative;
  margin-bottom: 2.75rem;
  background: $theme-primary;
  width: 100%;
  height: 20px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.boxed-hover {
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
  &:hover {
    box-shadow: 0 3px 10px rgba(27, 141, 222, 0.2);
    transform: translateY(-1px);
  }
}
.form-msg {
  p:last-child {
    margin-bottom: 0;
  }
}
.mobile-phone {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #008000;
  color: #fff !important;
  text-align: center;
  left: 20px;
  bottom: 20px;
  border-radius: 50px;
  .icon-phone {
    font-size: 1.75rem;
    line-height: 48px;
    &:before {
      transform: rotate(-90deg);
    }
  }
}
a.card {
  &:hover {
    border-style: solid;
    border-color: $theme-secondary !important;
    .get-started {
      color: $theme-secondary;
    }
  }
}
#coupon-modal {
  background: #fff2e2;
  font-size: 20px;
  line-height: 1.2;
  .orange {
    color: #d2601a;
  }
  .blue {
    color: #1c3c45;
  }
}
.notice-wrap {
  background-color: #dd3318;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 0;
  position: relative;
  text-align: center;
  z-index: 5000000;
}
.notice-wrap p {
  color: #e8f0fe;
  margin: 0 16px;
}
.closeBtn {
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
  color: #fff;
  padding: 0 5px;
  font-weight: 400;
}
.closeBtn:hover {
  color: #ccc;
}
.cta-card {
  box-shadow: 4px 4px 4px #ddd;
}
.card-cta {
  min-width: 235px;
  a {
    padding: 0.5rem 1rem;
    width: auto;
    height: auto;
    background-color: $theme-secondary;
    border-color: $theme-secondary;
    border-radius: 0.3rem;
    font-family: var(--font-family);
    line-height: 1.5;
  }
}
.lp {
  h1 {
    line-height: 1.2em;
  }
}
.why-choose {
  background: linear-gradient(13deg, #9e4913 55%, #c05c1e 55%);
}
.testimonial {
  border: 3px solid $theme-secondary;
  border-radius: 5px;
  .card-body {
    p {
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
    p span {
      background: #fff;
      line-height: 0.5rem;
      padding: 2.5rem 0.4rem 0.1rem;
      position: absolute;
      top: -30px;
      border: $theme-secondary 3px solid;
      border-radius: 50%;
      &::after {
        content: "\201D";
        font-family: Georgia, "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
        font-size: 5rem;
        font-weight: 700;
        position: relative;
        vertical-align: middle;
      }
    }
  }
  .card-footer p {
    font-weight: 600;
    font-size: 1.1rem;
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .card {
    &.course-box {
      &.horizontal {
        h3 {
          margin-right: 20px;
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 150px;
  }
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #phone-wrap {
    font-size: 1.2rem;
  }
  .closeBtn {
    right: 20px;
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    max-width: 140px;
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #phone-wrap {
    font-size: 1.3rem;
    .open-phone {
      font-size: 1.4rem;
      border-radius: 20px;
      &::before {
        left: 17px;
      }
      &::after {
        right: 17px;
      }
    }
    .open-blurb {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 20px;
        height: 28px;
        background-color: #1fbf66;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
    }
    .open-days {
      &::after {
        position: absolute;
        content: "";
        width: 20px;
        height: 28px;
        background-color: #1fbf66;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  #banner-wrap {
    background: transparent url(/images/banner-bg.jpg) no-repeat 50% 50% / cover;
    h1 {
      font-size: 2.5rem;
    }
    &.spanish {
      background: transparent url(/images/spanish-bg.jpg) no-repeat 50% 50% / cover;
    }
    &.ethical {
      background: transparent url(/images/ethical-contractor-bg.jpg) no-repeat 50% 20% / cover;
    }
  }
  #testimonial-wrap {
    background: transparent url(/images/testimonial-bg.jpg) no-repeat 50% 50% / cover;
  }
  .card {
    &.course-box {
      .course-img {
        width: 60% !important;
      }
      &.vertical {
        .course-img {
          max-width: 250px !important;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    h1 {
      font-size: 3rem;
    }
  }
  // .card {
  //   &.course-box {
  //     &.vertical {
  //       .course-img {
  //         max-width: 250px !important;
  //       }
  //       .course-img {
  //         max-width: 250px !important;
  //         img {
  //           transform: translateY(-25%);
  //           transition: transform .15s ease-in-out;
  //         }
  //       }
  //       &:hover {
  //         .course-img {
  //           overflow: visible;
  //           img {
  //             transform: translateY(-10%);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
