/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
// #nav-wrap {
//   background: $theme-nav-primary;
// }
#menutoggle {
  display: block;
  margin: 0;
  padding: 0 22px;
  width: auto;
  color: #fff;
  background: $theme-nav-primary;
  height: 45px;
  font-size: 18px;
  line-height: 46px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 4px 4px 0 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  span {
      display: none;
  }
  &::before {
    display: inline-block;
    margin-left: 0;
    width: 18px;
    height: 45px;
    line-height: 48px;
    content: "\e826";
    color: inherit;
    font-family: 'icons';
    text-align: center;
    font-size: 21px;
    vertical-align: top;
  }
  &.is-active:before {
    content: "\e844";
  }
  &:hover, &:active, &.is-active {
    background-color: lighten($theme-nav-primary, 7.5%);
    border-radius: 4px 4px 0 0;
  }
  &.is-active + ul {
    margin-bottom: 20px;
    max-height: 4000px;
    opacity: 1;
    transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out;
  }
}
#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  position: relative;
  z-index: 1000;
  // border-top: 1px solid $theme-nav-primary;
  ul {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    opacity: 0;
    max-height: 0;
    background-color: $theme-nav-primary;
    overflow: hidden;
    transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out;
    border-radius: 0 0 4px 4px;
    top: 1px;
    border: 0;
    position: relative;
    &.submenu {
      margin: 0;
      padding: 0;
      width: 100%;
      max-height: 0;
      // background-color: lighten($theme-nav-primary, 3%);
      background-color: rgba(255, 255, 255, 0.8);
      opacity: 1;
      position: relative;
      top: 0;
      transition: max-height 0.15s linear 0s;
      border-radius: 0;
      a {
        padding-left: 40px;
        background: none;
        color: #333;
        text-shadow: none;
        &:before {
          display: inline-block;
          margin-right: 5px;
          margin-left: -15px;
          width: 10px;
          height: inherit;
          content: "\00BB";
          color: inherit;
          font-family: 'icons';
          text-align: center;
          font-size: inherit;
          vertical-align: top;
        }
        &:hover {
          background-color: lighten($theme-nav-primary, 40%);
        }
      }
      span.submenu.level-2 {
        padding-left: 20px;
        // background-color: lighten($theme-nav-primary, 3%);
        display: block;
        color: #333;
        font-weight: 600;
        text-shadow: none;
        // position: absolute;
        // width: 55px;
        // height: 44px;
        // right: 0;
        // top: 0;
        // border-left: 1px dotted lighten($theme-nav-primary, 20%);
        &:hover {
          background-color: lighten($theme-nav-primary, 40%);
        }
        &.is-active {
          background-color: lighten($theme-nav-primary, 40%);
          border-bottom: 1px dotted lighten($theme-nav-primary, 20%);
        }
      }
      ul.submenu {
        // background-color: lighten($theme-nav-primary, 7.5%);
        // background-color: rgba(255, 255, 255, 0.8);
        border-radius: 0;
        li {
          a {
            // border-top: 1px dotted $theme-nav-primary;
            padding-left: 33px;
            transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
            color: #333;
            text-shadow: none;
            // &::before, &::after {
            //   display: none;
            // }
            &.is-active {
              background-color: transparent;
            }
            &:hover {
              padding-left: 35px;
              background-color: lighten($theme-nav-primary, 45%);
            }
          }
        }  
      }
      &.is-active {
        background-color: lighten($theme-nav-primary, 7.5%);
        border-bottom: 1px dotted lighten($theme-nav-primary, 20%);
      }
    }
  }
  li {
    display: block;
    margin: 0;
    padding: 0;
    height: auto;
    overflow: hidden;
    border: 0;
    border-top: 1px dotted lighten($theme-nav-primary, 20%);
    position: relative;
    &:first-child {
      border-top: 0;
    }
    &.onmobile {
      display: block;
    }
    &.language-select {
      padding: 12px 20px;
    }
  }
  a, span.submenu {
    float: none;
    display: block;
    margin: 0;
    padding: 9px 20px;
    height: auto;
    color: #fff;
    line-height: 1.6em;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    text-align: left;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    border: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background 0.17s linear 0s;
    &.login:before, &.cart:before {
      display: inline-block;
      margin-right: 5px;
      color: inherit;
      content: "\ea77";
      font-family: 'Material Icons Round';
      font-weight: normal;
      font-size: 110%;
      vertical-align: top;
      width: 23px;
      text-align: left;
    }
    &.cart:before {
      content: "\e8cc";
      font-family: 'Material Icons Outlined';
    }
  }
  span.submenu:after, a.submenu:after {
    float: right;
    margin-left: 10px;
    width: 15px;
    height: inherit;
    content: "\e817";
    color: inherit;
    font-family: 'icons';
    text-align: center;
    font-size: 105%;
    vertical-align: top;
  }
  span.submenu.is-active, a.submenu.is-active {
    border-bottom: 1px dotted lighten($theme-nav-primary, 7.5%);
    + ul.submenu {
      max-height: 2000px;
      overflow-y: auto;
    }
  }
  span.submenu.is-active:after, a.submenu.is-active:after {
    content: "\e816";
  }
  a:hover, 
  a.is-active, 
  // li:hover span.submenu, 
  li:hover a.submenu, 
  ul.submenu a.is-active {
    background: lighten($theme-nav-primary, 7.5%);
  }
}

@media (min-width: 36em) { /* Small devices (landscape phones, 576px and up) */
/*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
    span {
      display: inline-block;
    }
    &:before {
      margin-right: 10px;
    }
  }
}
@media (min-width: 62em) { /* Large devices (desktops, 992px and up) */
  #nav-wrap {
    background: $theme-nav-primary;
  }
  #menutoggle {
    display: none;
  }
  #nav {
    display: flex;
    height: auto;
    background: $theme-nav-primary;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
    ul {
      display: flex;
      align-items: center;
      // float: left;
      width: 100%;
      height: auto;
      max-height: 100%;
      opacity: 1;
      margin: 0;
      position: relative;
      z-index: 10;
      top: 0;
      overflow: visible;
      &.submenu {
        display: block;
        float:left;
        margin: 0;
        padding: 0;
        width: 350px;
        max-height: 0;
        position: absolute;
        opacity: 0;
        background-color: lighten($theme-nav-primary, 7.5%);
        top: 95%;
        z-index: 10;
        border-radius: 0 3px 3px 3px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
        overflow: hidden;
        overflow-y: auto;
        &.left {
          right: 0;
          left: auto !important;
          border-radius: 3px 0 3px 3px;
        }
        li {
          float: none;
          display: block;
          margin: 0;
          padding: 0;
          border: 0;
          height: auto;
          &:first-child a {
            border-top: 0;
          }
        }
        a {
          float: none;
          display: block;
          margin: 0;
          padding: 7px 15px 7px 28px;
          height: auto;
          color: #fff;
          text-shadow: 0 1px 0 rgba(0,0,0,.4);
          text-transform: none;
          text-align: left;
          line-height: 1.5em;
          border-radius: 0;
          border: 0;
          border-bottom: 1px dotted lighten($theme-nav-primary, 20%);
        }
        span.submenu.level-2 {
          padding: 7px 15px 7px 20px;
          height: auto;
          display: block;
          text-align: left;
          position: relative;
          font-weight: normal;
          color: #fff;
          text-shadow: 0 1px 0 rgba(0,0,0,.4);
          border-bottom: 1px dotted lighten($theme-nav-primary, 20%);
          &:hover {
            background-color: lighten($theme-nav-primary, 3.5%);
          }
          &.is-active {
            background-color: lighten($theme-nav-primary, 3.5%);
            & + ul.submenu {
              max-height: 350px !important;
              overflow-y: auto;
            }
            &:after {
              content: "\e816";
            }
          }
          &:after {
            display: block;
            float: right;
            margin-left: 10px;
            width: 15px;
            height: inherit;
            content: "\e817";
            color: inherit;
            font-family: 'icons';
            text-align: center;
            font-size: 105%;
          }
        }
        ul.submenu {
          float: none;
          display: block;
          position: relative;
          padding: 0 !important;
          box-shadow: none;
          max-height: 0 !important;
          overflow: hidden;
          background-color: rgba(255, 255, 255, 0.7);
          li {
            overflow: hidden;
            a {
              border-radius: 0;
              padding-left: 33px !important;
              transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
              color: #333;
              // text-shadow: 0 1px 0 rgba(0,0,0,.4);
              &:hover {
                padding-left: 35px !important;
                // background-color: lighten($theme-nav-primary, 3.5%);
              }
            }
          }  
        }
      }
    }
    li {
      flex-grow: 1;
      margin: 0;
      padding: 0;
      height: 100%;
      width: auto;
      min-width: auto;
      position: relative;
      border: 0;
      overflow: visible;
      // border-right: 1px solid lighten($theme-nav-primary, 10%);
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      &:first-child {
        margin-left: 0;
        border-left: 0;
        // border-left: 1px solid lighten($theme-nav-primary, 10%);
        border-left: 1px solid rgba(255, 255, 255, 0.3);
      }
      &.last {
        border-right: 0;
      }
      &.right {
        float: right;
        margin-right: 0;
        border-right: 0;
      }
      &.onmobile {
        display: none;
      }
      &:hover {
        z-index: 11;
      }
    }
    a, span.submenu {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 10px;
      width: 100%;
      height: 100%;
      border: 0;
      color: #fff;
      font-weight: normal;
      // font-size: 14px;
      // line-height: 39px;
      text-align: center;
      // text-transform: uppercase;
      // border-radius: 3px;
      transition: background-color 0.2s linear 0s;
      cursor: pointer;
    }
    span.submenu:after, a.submenu:after {
      display: none;
    }
    a:hover, a.is-active, li:hover span.submenu, li:hover a.submenu, li:hover a.submenu, ul.submenu a:hover, span.submenu.is-active, a.submenu.is-active {
      background-color: lighten($theme-nav-primary, 7.5%);
    }
    li:hover a.submenu, li:hover span.submenu, li:hover a.submenu, span.submenu.is-active.hover, a.submenu.is-active.hover {
      border-radius: 0;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    li:hover ul.submenu, ul.submenu.is-active {
      padding: 0;
      left: 0;
      max-height: 470px;
      opacity: 1;
    }
    ul.submenu li a:hover, ul.submenu li a.is-active {
      background-color: lighten($theme-nav-primary, 3.5%);
    }
  }
}
